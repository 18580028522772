import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youuni.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/uni_1.svg"
import HeaderImg from "../../assets/img/youuni/Img1.png"
import Icon1 from "../../assets/img/youuni/Icon1.png"
import Icon2 from "../../assets/img/youuni/Icon2.png"
import Icon3 from "../../assets/img/youuni/Icon3.png"
import Icon4 from "../../assets/img/youuni/Icon4.png"
import Icon5 from "../../assets/img/youuni/Icon5.png"
import Icon6 from "../../assets/img/youuni/Icon6.png"
import Icon7 from "../../assets/img/youuni/Icon7.png"
import Icon8 from "../../assets/img/youuni/Icon8.png"
import Icon9 from "../../assets/img/youuni/Icon9.png"
import GroupImg1 from "../../assets/img/youuni/Img2.png"
import GroupImg2 from "../../assets/img/youuni/Img3.png"
import TickIcon from "../../assets/img/youuni/tick.svg"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="Youuni" keywords={[`youpal`, `youpal group`, `youuni`]} />
      <div className="youuniMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                  Create Measurable Learning Experiences With Youuni
                </h2>
              </div>
            </div>

            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
                Youuni is a Learning Management System that allows you to create and manage online training and courses for your employees, students, and clients.
              </p>
            </div>

            <div className="col-12 order-4 order-md-3">
              <p className="internalNarrowCol mt-2">
                It's the best for businesses, universities, and other facilities to continuously create value for people.
              </p>
            </div>

            <div className="col-12 order-5 order-md-4">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-4 mt-md-5 mb-md-0 mb-4 mx-auto d-block">
                <span className="text-white">Free demo</span>
              </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-5">   
              <img className="headerImg" src={HeaderImg} />
            </div>

          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <p className="internalNarrowCol">
            If you have already identified your learning goals using a LMS, our full-scope solutions and e-learning specialists are always there to guide you for maximum efficiency.</p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Be in Control</h4>
              <p>
              Youuni provides various roles with different access. There're a few default roles: student, teacher, coach, content manager, user manager, administrator. You can choose to provide permissions as per the different roles.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Personalised Learning</h4>
              <p>
              Our platform is constantly updated with advanced features. These allow you to personalise your learning process by adapting training material to your students.</p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Content Creation</h4>
              <p>
              Youuni offers feature-rich content creation and designing tools. Create this engaging and interactive modules, courses, and activities seamlessly.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <div>
                <img src={Icon4} />
              </div>
              <h4>Collaborative Environment</h4>
              <p>
              Our interactive chat comes with many useful features, such as sharing asynchronous messages, files, documents, and even to-do lists. Create various workspaces to make the best of collaborative learning!
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon5} />
              </div>
              <h4>Video Conferencing</h4>
              <p>
              Youuni has a live meeting solution that creates the feeling of the real classroom. It comes with a whiteboard, screen sharing and video and audio recording features.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon6} />
              </div>
              <h4>Library & Forum</h4>
              <p>
              Our Media Library keeps all the course-related documents, images, videos, and other media files in one place. 
              <br/> <br/>
              We also have a Forum section specific for each course. Students can engage with each other and experience "learning while teaching".
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection internalSection1">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h3 className="text-left">
                A Learning Management System Saves You Mistakes
                </h3>
                <p>
                Create courses with a built-in training builder or integrate other learning marketplaces to provide access to hundreds of programmes to your staff.
</p><p>
Ensure your teams is aligned with your business objectives by assessing their skills and training needs.
</p>
                <div className="buttonArea">
                <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">BENEFITS</div>
              <h3>How can you benefit from Youuni?</h3>
              <div className="row mt-0 mt-md-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Reduce Training Costs</h4>
                        <p>
                        Reskilling and updating your employees’ capacities need not be a costly affair. With Youuni, hire professionals to continuously create training tailor-made training programmes. These modules are thereafter free for your employees.
                        </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Onboard Effortlessly</h4>
                        <p>
                        Document all your internal processes and turn them into engaging training material. Save considerable time and costs by using this material to onboard new employees.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Find Skill Gaps</h4>
                        <p>
                        LMS makes learning easy by helping you identify the right programmes and resources to learn from. Our AI and algorithm suggest courses based on a user’s learning history, role and preferences. Our LMS also combines learning paths and methods.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection internalSection2">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">COLLABORATE</div>
                <p className="mt-5">
                Use Youuni collaboration features to build an interactive environment inside your LMS.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Interactive chat
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Unique workspaces
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Video conferencing
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Media library
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Forum
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
